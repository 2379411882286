import axios from 'axios';
import store from '@/store'; // Adjust the path to match your Vuex store location  
import { EventBus } from '../config/event-bus';  // Import the event bus

export default {
  // Function to make asynchronous HTTP requests using Axios
  async request(endpoint, datas) {

    const utcDateTime = new Date().toUTCString();
    // Ensure datas is an object
    let payload = { ...datas };

    // Check if store.state.user.token_data exists
    if (!payload?.token_data) {
      if (store.state?.user?.token_data) {
        payload.token_data = store.state.user.token_data;
        payload.user_token = store.state.user.user_token;
        payload.utc_date = utcDateTime;
      }
    }

    var data = JSON.stringify(payload);
    var config = {
      method: 'post',
      url: process.env.VUE_APP_API_BASE_URL + endpoint,
      // withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };
    const response = await axios(config)
    if (response.data.result) {
      data = response.data.result
      if (data.autherror) {
        EventBus.$emit('show-modal', data.autherror);
      }
    }else {
      console.log(response.data.error)
    }
    return response
  },
}
